<template>
    <div class="course">
        <div class="course_content">
            <div class="course_type">
                <div class="type">课程分类</div>
                <div class="types">
                    <div style="height: 13px;color: #3768fa;">
                        全部
                    </div>
                    <el-dropdown v-for="item in coursetype" :key="item.id"
                        style="display: flex ; align-items: center; margin: 0px 8px;">
                        <span class="el-dropdown-link">
                            {{ item.name }}
                            <el-icon>
                                <ArrowDown />
                            </el-icon>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="item in coursetype" :key="item.id">{{ item.name
                                }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="course_type" style="margin-top: 10px;">
                <div class="type">课程筛选</div>
                <div class="types" style="">
                    <div style="color: #3768fa;">
                        全部
                    </div>
                    <div v-for="item in coursescreen" :key="item.id" style="color: #616161;cursor: pointer;">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="course_type" style="margin-top: 10px;">
                <div class="type">排序方式</div>
                <div class="types" style="">
                    <div style="color: #3768fa;">
                        全部
                    </div>
                    <div v-for="item in sortway" :key="item.id" style="color: #616161;cursor: pointer;">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="course_text">
                <div class="course_text_children" v-for="item in 12" style="margin-top: 10px;" @click="detaiVideo(item.id)">
                    <div class="course_text_children_img">

                    </div>
                    <div class="course_text_children_text">
                        POP商家售后客服认证主题系列课程京哈傻傻的啊实打实的
                    </div>
                    <div class="course_text_children_time">
                        <div>
                            商家学习中心
                        </div>
                        <div>2.0万人学习</div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;">
                <el-pagination v-model:current-page="currentPage3" v-model:page-size="pageSize3" :small="small"
                    :disabled="disabled" :background="background" layout="prev, pager, next, jumper" :total="100"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" />
            </div>
        </div>

    </div>
</template>

<script setup >
import { ref, reactive } from 'vue'
import { ArrowDown } from '@element-plus/icons-vue'
import router from '../router';
const coursetype = ref([
    {
        id: "1",
        name: "招商入住"
    },
    {
        id: "2",
        name: "商品运营"
    },
    {
        id: "3",
        name: "仓配客售"
    }, {
        id: "4",
        name: "数据分析"
    },
    {
        id: "5",
        name: "免费流量"
    }
])
const coursescreen = ref([
    {
        id: "1",
        name: "视频课程"
    },
    {
        id: "2",
        name: "图文课程"
    },
    {
        id: "3",
        name: "直播课程"
    }, {
        id: "4",
        name: "学习计划"
    }
])
const sortway = ref([
    {
        id: "1",
        name: "发布时间"
    },
    {
        id: "2",
        name: "观看人数"
    },
    {
        id: "3",
        name: "课程满意度"
    }
])
const detaiVideo = (id)=>{
    console.log(id)
    router.push({
        path:"/coursehour"
    })
}
const currentPage3 = ref(1)
const pageSize3 = ref(10)
const small = ref(false)
const background = ref(false)
const disabled = ref(false)
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
</script>

<style scoped lang="scss"> .course {
     width: 100%;
     min-height: 1600px;
     background-color: white;
     display: flex;
     justify-content: center;

     :deep(.el-dropdown-link) {
         display: flex;
         align-items: center;
         cursor: pointer;
     }

     .course_content {
         width: 69%;

         .course_type {
             display: flex;

             .type {
                 width: 7em;
                 height: 2.5em;
                 background: url("../assets/leaning/bg3.png") no-repeat;
                 background-size: 100% 100%;
                 display: flex;
                 justify-content: center;
                 align-items: center;
                 color: #3768fa;
                 font-size: 1.3em;
                 align-items: center;
             }

             .types {
                 display: flex;
                 align-items: center;

                 div {
                     width: 6em;
                     text-align: center;
                     font-size: 14px;
                 }
             }
         }

         .course_text {
             margin-top: 20px;
             display: flex;
             justify-content: space-between;
             flex-wrap: wrap;

             .course_text_children {
                 width: 285px;
                 height: 248px;
                 cursor: pointer;
                 background-color: white;

                 // border: 1px solid gainsboro;
                 .course_text_children_img {
                     width: 100%;
                     height: 150px;
                     background: url("../assets/leaning/rule2.png") no-repeat;
                     background-size: 100% 100%;
                 }

                 .course_text_children_text {
                    
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    word-break: break-all;
                    padding: 20px;
                    font-size: 1.3em;
                 }

                 .course_text_children_time {
                     display: flex;
                     justify-content: space-between;
                     padding: 0px 20px;
                 }
             }

             .course_text_children:hover {
                 box-shadow: 1px 1px 10px rgb(214, 214, 214);
                 transform: translateY(-5px);
             }
         }
     }
 }</style>